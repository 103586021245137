export const LINKS = [
  {
    to: "/dashboard",
    title: "My Digital Cards",
    translation: "myDigitalCard",
  },
  {
    to: "/dashboard/reviews",
    title: "Reviews",
    translation: "reviews",
  },
  {
    to: "/dashboard/account",
    title: "Account Settings",
    translation: "accSettings",
  },
]
