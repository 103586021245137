import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import ClipLoader from "react-spinners/ClipLoader"
import Auth from "../layout/auth/auth"
import Button from "../common/Button"
import Api from "../api/api"
import { Notifications } from "../helper/notifications"
import { OVERRIDE } from "../utils/constants"
import { useSelector } from "react-redux"

const Reset = () => {
  const history = useHistory()
  const language = useSelector((state) => state.language)
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({
    email: "",
    code: "",
    password: "",
    re_password: "",
  })

  const inputHandle = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    if (data.password !== data.re_password) {
      return Notifications("warning", "Password does not match")
    }
    setIsDisable(true)
    const res = await Api.resetPassword(data)
    if (res.status === 200) {
      history.push("/login")
      Notifications("success", res.data.message)
    }
    setIsDisable(false)
  }

  return (
    <Auth title={language.resetPassword}>
      <form
        onSubmit={submitHandle}
        className="auth_form"
      >
        <div className="c_input_wrapper">
          <input
            type="email"
            name="email"
            value={data.email}
            onChange={inputHandle}
            placeholder={language.emailAddress}
            required
          />
        </div>
        <div className="c_input_wrapper">
          <input
            type="number"
            name="code"
            value={data.code}
            onChange={inputHandle}
            placeholder={language.resetCode}
            required
          />
        </div>
        <div className="c_input_wrapper">
          <input
            type="password"
            name="password"
            value={data.password}
            onChange={inputHandle}
            placeholder={language.password}
            required
          />
        </div>
        <div className="c_input_wrapper">
          <input
            type="password"
            name="re_password"
            value={data.re_password}
            onChange={inputHandle}
            placeholder={language.confirmPassword}
            required
          />
        </div>
        <Button
          disabled={isDisable}
          className="btn_primary"
        >
          {!isDisable ? (
            language.reset
          ) : (
            <ClipLoader
              color="#FFFFFF"
              loading={true}
              cssOverride={OVERRIDE}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
        </Button>
      </form>
    </Auth>
  )
}

export default Reset
