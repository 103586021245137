import { memo, useState, useEffect } from "react"
import CustomModal from "../../../layout/modal/modal"
import { useDispatch } from "react-redux"
import { editIcon } from "../../../store/slices/cardSlice"
import Api from "../../../api/api"

const EditIcons = ({ onHide, iconData, data, language }) => {
  const dispatch = useDispatch()
  const [type, setType] = useState("")

  const editIconHandle = async (icon) => {
    if (!type) return

    try {
      const response = await fetch(icon)
      const blob = await response.blob()

      const reader = new FileReader()
      reader.onloadend = async () => {
        const base64String = reader.result
        const formData = {
          id: iconData.id,
          image: base64String,
          type: type,
        }

        dispatch(editIcon({ ...iconData, icon: base64String, type }))
        onHide()
        await Api.updateIcon(formData)
      }

      reader.readAsDataURL(blob)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    setType(iconData.type)
  }, [iconData])

  return (
    <CustomModal
      title={language.editIcon}
      onHide={onHide}
    >
      <div className="icons_cont">
        <div style={{ width: "100%" }}>
          <label>{language.iconUsedFor}</label>
          <div className="c_input_wrapper">
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">--{language.select}--</option>
              <option value="wa">{language.whatsappNumber}</option>
              <option value="sms">{language.smsNumber}</option>
              <option value="phn">{language.phoneNumber}</option>
              <option value="mail">{language.mailAddress}</option>
              <option value="link">{language.linkAddress}</option>
            </select>
          </div>
        </div>
        {data &&
          data.map(({ imageUrl }, index) => (
            <img
              key={index}
              onClick={() => editIconHandle(imageUrl)}
              src={imageUrl}
              alt="Icons"
            />
          ))}
      </div>
    </CustomModal>
  )
}

export default memo(EditIcons)
