import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import ClipLoader from "react-spinners/ClipLoader"
import Auth from "../layout/auth/auth"
import Button from "../common/Button"
import Api from "../api/api"
import { Notifications } from "../helper/notifications"
import { OVERRIDE } from "../utils/constants"
import { useSelector } from "react-redux"

const Forget = () => {
  const history = useHistory()
  const language = useSelector((state) => state.language)
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({ email: "" })

  const inputHandle = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)
    const res = await Api.forgetPassword(data)
    if (res.status === 200) {
      history.push("/reset")
      Notifications("success", res.data.message)
    }
    setIsDisable(false)
  }

  return (
    <Auth title={language.forgetPassword}>
      <form
        onSubmit={submitHandle}
        className="auth_form"
      >
        <div className="c_input_wrapper">
          <input
            type="email"
            name="email"
            value={data.email}
            onChange={inputHandle}
            placeholder={language.emailAddress}
            required
          />
        </div>
        <Button
          disabled={isDisable}
          className="btn_primary"
        >
          {!isDisable ? (
            language.forget
          ) : (
            <ClipLoader
              color="#FFFFFF"
              loading={true}
              cssOverride={OVERRIDE}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
        </Button>
      </form>
    </Auth>
  )
}

export default Forget
