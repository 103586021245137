import React, { useCallback, useLayoutEffect, useState } from "react";
import "./collection.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import headingLine from "../../assets/homepage/headingLine.png";
import img1 from "../../assets/slider/mobile1.svg";
import img2 from "../../assets/slider/mobile2.svg";
import img3 from "../../assets/slider/mobile3.svg";
import img4 from "../../assets/slider/mobile4.svg";
import img5 from "../../assets/slider/mobile5.svg";
import img6 from "../../assets/slider/mobile6.svg";
import img7 from "../../assets/slider/mobile7.svg";

import { useTranslation } from "react-i18next";
import Api from "../../api/api";

function Collection() {
  let [ltl, setLTL] = useState(true);
  const { t } = useTranslation();
  const imgs = [img1, img2, img3, img4, img5, img6, img7];
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDesigns = useCallback(async () => {
    const res = await Api.getDesigns();
    if (res.status === 200) {
      // console.log(res.data.data);
      setData(res.data.data);
      setIsLoading(false);
    }
  }, []);

  useLayoutEffect(() => {
    getDesigns();
  }, []);

  return (
    <div className="bg-white bLightBlue ">
      <div className="max-w-screen-xl m-auto section bLightBlue">
        <div className="headWrapper">
          <div className="headDiv">
            <h1 className="hHead">{t("ourCaptivatingTemplateCollection")}</h1>

            <p className={ltl ? "headingLineImg" : "headingLineImgRTL"}>
              <img src={headingLine} alt="" />
            </p>
          </div>
        </div>
        {/* <img className="w-auto h-[500px]" src={svgImage} alt="slide_image" /> */}
        <div>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={4}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
              slideShadows: false,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
          >
            {data.map((img, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="flex justify-center">
                    <img
                      className="w-[auto] h-auto sm:h-[300px] md:h-[500px]"
                      src={img.imageUrl}
                      alt="slide_image"
                    />
                  </div>
                </SwiperSlide>
              );
            })}

            {/* Swiper pagination and buttons*/}
            {/*<div className="slider-controler">*/}
            {/*    <div className="swiper-button-prev slider-arrow">*/}
            {/*        <ion-icon name="arrow-back-outline"></ion-icon>*/}
            {/*    </div>*/}
            {/*    <div className="swiper-button-next slider-arrow">*/}
            {/*        <ion-icon name="arrow-forward-outline"></ion-icon>*/}
            {/*    </div>*/}
            {/*    <div className="swiper-pagination"></div>*/}
            {/*</div>*/}
          </Swiper>
        </div>
        <div className="flex flex-row justify-center items-center my-5">
          <span className="text-xs text-gray-700">{t("needTheSameThing")}</span>
          <button className="bg-blue-600 text-white text-xs rounded-md px-4 py-2 ml-4">
            {t("clickToGet")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Collection;
