import "./preview.css"
import { useSelector } from "react-redux"
import CardCover from "./components/cover"
import CardLogo from "./components/logo"
import OpeningHours from "./components/hours"
import IconsSlider from "./components/icons"
import Gallery from "./components/gallery"
import Reviews from "./components/reviews"
import Floating from "./components/floating"

const CardPreview = () => {
  const {
    bg_color,
    text_color,
    logo,
    cover_photo,
    bg_image,
    cover_color,
    likes,
    title,
    slogan,
    number,
    hours_bg,
    floating_bg,
    floating_icon,
    reviews_bg,
    hours,
    reviews,
    icons,
    settings,
    language,
  } = useSelector((state) => state.card)

  return (
    <>
      <div
        className={`card_preview ${
          window.location.pathname.includes("/card/") && "card_preview_width"
        }`}
        style={{
          background:
            bg_image && settings.bg_image ? `url(${bg_image})` : bg_color,
          backgroundPosition: bg_image && settings.bg_image && "initial",
          backgroundSize: bg_image && settings.bg_image && "cover",
          backgroundRepeat: bg_image && settings.bg_image && "no-repeat",
          color: text_color,
        }}
      >
        <CardCover
          likes={likes}
          coverPhoto={cover_photo}
          coverColor={cover_color}
          showCover={settings.cover_photo}
          showLikeBtn={settings.like_button}
        />
        <div
          className="card_preview_move"
          style={{ transform: `translateY(${settings.logo ? "-55px" : "0"})` }}
        >
          {settings.logo && (
            <CardLogo
              title={title}
              logoURL={logo}
              bg={bg_color}
              color={text_color}
              language={language}
            />
          )}
          {settings.title && <div className="card_title">{title}</div>}
          {settings.slogan && <div className="card_subtitle">{slogan}</div>}
          {settings.icons && (
            <IconsSlider
              language={language}
              title={title}
              icons={icons}
            />
          )}
          {settings.hours && (
            <OpeningHours
              bg={hours_bg}
              color={text_color}
              hours={hours}
              language={language}
            />
          )}
          {settings.gallery && (
            <Gallery
              bg={bg_color}
              color={text_color}
              language={language}
            />
          )}
          {settings.reviews && (
            <Reviews
              bg={bg_color}
              color={text_color}
              logo={logo}
              title={title}
              language={language}
              reviews_bg={reviews_bg}
              reviews={reviews}
            />
          )}
        </div>
        <Floating
          language={language}
          title={title}
          number={number}
          color={text_color}
          icon={floating_icon}
          fbg={floating_bg}
          isShow={settings.floating}
        />

        <div
          className="copyright"
          style={{
            margin: "10px 0",
            textAlign: "center",
            fontSize: 12,
            color: text_color,
          }}
        >
          Copyright © {new Date().getFullYear()} Wazcard. All rights reserved.
        </div>
      </div>
    </>
  )
}

export default CardPreview
