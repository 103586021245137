import React, { useState } from "react";
import "./how_it_work.css";
import headingLine from "../../assets/homepage/headingLine.png";
import frame1 from "../../assets/Frame 1.png";
import frame2 from "../../assets/Frame 2.png";
import frame3 from "../../assets/Frame 3.png";
import frame4 from "../../assets/Frame 4.png";
import line30 from "../../assets/Line 30.png";
import line31 from "../../assets/Line 31.png";
import { useTranslation } from "react-i18next";

function Working({ ltl, working }) {
  const { t } = useTranslation();
  return (
    <div className="bLightBlue flex justify-center">
      <section className="max-w-screen-xl section bLightBlue">
        <div className="headWrapper">
          <div className="headDiv">
            <h1 className="hHead">
              {t("howItWorks")}
              {/* How it works */}
            </h1>

            <p className={ltl ? "headingLineImg" : "headingLineImgRTL"}>
              <img src={headingLine} alt="" />
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-7 gap-4">
          <div className={ltl ? "order-1" : "order-7"}>
            <h1
              className={
                ltl
                  ? "hiwNumber  text-center text-lg font-semibold text-blue-500 "
                  : "hiwNumberRTL text-center text-lg font-semibold text-blue-500"
              }
            >
              01
            </h1>

            <div className="flex flex-row justify-center my-2 ">
              <img src={frame1} className="h-8 w-8" />
            </div>

            <h1 className=" font-semibold  text-center mb-2">
              {t("workingStep1Title")}
            </h1>
            <p
              className={
                ltl
                  ? "hiwText text-center text-xs"
                  : "hiwTextRTL text-center text-xs"
              }
            >
              {t("workingStep1Description")}
            </p>
          </div>

          <div
            className={
              "hidden md:block mt-5 " +
              (ltl ? " order-2 scale-x-[1]" : " order-6 scale-x-[-1] ")
            }
          >
            <img src={line30} className={"w-full "} />
          </div>

          <div className={ltl ? "order-3" : "order-5"}>
            <h1
              className={
                ltl
                  ? "hiwNumber text-center text-lg font-semibold text-blue-500 "
                  : "hiwNumberRTL text-center text-lg font-semibold text-blue-500"
              }
            >
              02
            </h1>

            <div className="flex flex-row justify-center my-2">
              <img src={frame2} className="h-8 w-8" />
            </div>

            <h1 className=" font-semibold text-center mb-2 ">
              {t("workingStep2Title")}
            </h1>
            <p
              className={
                ltl
                  ? "hiwText text-center text-xs"
                  : "hiwTextRTL text-center text-xs"
              }
            >
              {t("workingStep2Description")}
            </p>
          </div>

          <div
            className={
              "hidden md:block mt-5 " +
              (ltl ? " order-4  " : " order-4 scale-x-[-1] ")
            }
          >
            <img src={line31} className="w-full " />
          </div>

          <div className={ltl ? "order-5" : "order-3"}>
            <h1
              className={
                ltl
                  ? "hiwNumber text-center text-lg font-semibold text-blue-500 "
                  : "hiwNumberRTL text-center text-lg font-semibold text-blue-500"
              }
            >
              03
            </h1>

            <div className="flex flex-row justify-center my-2">
              <img src={frame3} className="h-8 w-8" />
            </div>

            <h1 className=" font-semibold text-center mb-2 ">
              {t("workingStep3Title")}
            </h1>
            <p
              className={
                ltl
                  ? "hiwText text-center text-xs"
                  : "hiwTextRTL text-center text-xs"
              }
            >
              {t("workingStep3Description")}
            </p>
          </div>

          <div
            className={
              "hidden md:block mt-5 " +
              (ltl ? " order-6 " : " order-2 scale-x-[-1] ")
            }
          >
            <img src={line30} className="w-full " />
          </div>

          <div className={ltl ? "order-7" : "order-1"}>
            <h1
              className={
                ltl
                  ? "hiwNumber text-center text-lg font-semibold text-blue-500 "
                  : "hiwNumberRTL text-center text-lg font-semibold text-blue-500"
              }
            >
              04
            </h1>

            <div className="flex flex-row justify-center my-2">
              <img src={frame4} className="h-8 w-8" />
            </div>

            <h1 className=" font-semibold text-center mb-2 ">
              {t("workingStep4Title")}
            </h1>
            <p
              className={
                ltl
                  ? "hiwText text-center text-xs"
                  : "hiwTextRTL text-center text-xs"
              }
            >
              {t("workingStep4Description")}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Working;
