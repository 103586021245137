import React from "react";
import phone from "../../assets/phone.png";
import qr from "../../assets/businessInfo/Qr code.png";
import input from "../../assets/businessInfo/input.png";
import social from "../../assets/businessInfo/social.png";

import { RxDotFilled } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import BusinessCard from "../home/bussinessCard";

function BusinessInfo({ ltl, business, image }) {
  const { t } = useTranslation();
  return (
    <div className="bg-white flex justify-center">
      <div
        className={
          "max-w-screen-xl grid grid-cols-1 md:grid-cols-2 bg-white p-8 "
        }
      >
        <div
          className={" flex  flex-col justify-center " + (!ltl && "md:order-2")}
        >
          <h1 className="text-lg md:text-2xl font-semibold text-gray-900 tracking-wide">
            {t("bussinessTitle")}
          </h1>
          <span className="text-xs font-thin text-gray-800 mt-2 ">
            {t("bussinessDescription")}
          </span>
          <div>
            <h1 className="flex flex-row items-center mt-3 font-semibold ">
              <RxDotFilled className="mr-1" /> {t("business1Label")}
            </h1>
            <div className="ml-5">
              <span className="text-xs font-thin text-gray-800 ">
                {t("business1Description")}
              </span>
            </div>
          </div>

          <div>
            <h1 className="flex flex-row items-center mt-3 font-semibold ">
              <RxDotFilled className="mr-1" /> {t("business2Label")}
            </h1>
            <div className="ml-5">
              <span className="text-xs font-thin text-gray-800 ">
                {t("business2Description")}
              </span>
            </div>
          </div>

          <div>
            <h1 className="flex flex-row items-center mt-3 font-semibold ">
              <RxDotFilled className="mr-1" /> {t("business3Label")}
            </h1>
            <div className="ml-5">
              <span className="text-xs font-thin text-gray-800 ">
                {t("business3Description")}
              </span>
            </div>
          </div>
        </div>
        <div
          className={
            "flex flex-row justify-center items-center my-3 md:my-0 " +
            (!ltl && "md:order-1")
          }
        >
          <img src={image} className="max-h-[40vh] md:max-h-[60vh] w-auto " />
          {/* <div className="relative">
          <img src={phone} className="max-h-[40vh] md:max-h-[60vh] w-auto " />
          <div className="absolute top-[10px] left-[-100px]">
            <img src={qr} className=" h-36 " />
          </div>

          <div className="absolute top-[50px] md:top-[150px] left-[80px] md:left-[130px] w-[200px]">
            <img src={input} className=" h-32 w-[100] md:w-[200px] " />
          </div>

          <div className="absolute bottom-[-10px] left-[-90px]">
            <img src={social} className=" h-28 w-40" />
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
}

export default BusinessInfo;
