import Switch from "react-switch"
import DesignCard from "../../../layout/designCard/designCard"
import { useDispatch, useSelector } from "react-redux"
import { designCard, editHours } from "../../../store/slices/cardSlice"
import Api from "../../../api/api"

const OperatingHours = ({ toggle, setToggle }) => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.card)
  const language = useSelector((state) => state.language)

  const colorInputHandle = (e) => {
    const { name, value } = e.target
    dispatch(designCard({ ...state, [name]: value }))
  }

  const hoursHandle = async (id, label, from, to, isOpen) => {
    dispatch(editHours({ id, label, from, to, isOpen }))
    await Api.updateHours({ id, cid: state.id, from, to, isOpen })
  }

  const submitColorData = async (e) => {
    const { name, value } = e.target
    await Api.updateHoursColor({ id: state.id, [name]: value })
  }

  return (
    <DesignCard
      title={language.openingHours}
      toggle={toggle}
      onShow={() => setToggle(3, !toggle)}
    >
      <div className="design_input_wrapper">
        <label>{language.backgroundColor}</label>
        <input
          type="color"
          name="hours_bg"
          value={state.hours_bg}
          onChange={colorInputHandle}
          onBlur={submitColorData}
        />
      </div>
      {state.hours.map(({ id, label, from, to, isOpen }) => (
        <Day
          id={id}
          label={label}
          from={from}
          to={to}
          isOpen={isOpen}
          language={language}
          hoursHandle={hoursHandle}
        />
      ))}
    </DesignCard>
  )
}

const Day = ({ id, label, from, to, isOpen, hoursHandle, language }) => {
  return (
    <div
      className="design_wrapper hours_wrapper"
      key={label}
    >
      <p style={{ marginBottom: 4 }}>{language[label.toLowerCase()]}</p>
      <div
        className="main_icon_inputs"
        style={{ display: "flex", alignItems: "center", gap: 8 }}
      >
        <input
          type="time"
          value={from}
          onChange={(e) => hoursHandle(id, label, e.target.value, to, isOpen)}
        />
        <input
          type="time"
          value={to}
          onChange={(e) => hoursHandle(id, label, from, e.target.value, isOpen)}
        />
        <div className="icon_toggle">
          <Switch
            checked={isOpen}
            onChange={(e) => hoursHandle(id, label, from, to, !isOpen)}
            width={42}
            height={20}
            onColor="#2563EB"
            handleDiameter={16}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          />
        </div>
      </div>
    </div>
  )
}

export default OperatingHours
