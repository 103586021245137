import Cookies from "js-cookie"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "../../utils/constants"
import Api from "../../api/api"

const userSlice = createSlice({
  name: "userSlice",
  initialState: JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME)) || {},
  reducers: {
    addUser: (state, { payload }) => {
      localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(payload))
      return payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMe.fulfilled, (state, { payload }) => {
      localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(payload))
      return payload
    })
  },
})

//get me profile
export const getMe = createAsyncThunk("user/getMe", async () => {
  try {
    const res = await Api.getMyProfile()
    if (res.status === 200) {
      return res.data.data
    } else if (res.status === 500) {
      Cookies.remove(JWT_STORAGE_NAME)
      localStorage.removeItem(JWT_STORAGE_NAME)
      localStorage.removeItem(LOCAL_STORAGE_NAME)
      window.location.reload()
    }
  } catch (error) {
    console.log(error)
  }
})

export const { addUser } = userSlice.actions
export default userSlice.reducer
