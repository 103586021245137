import { memo, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import Carousel from "react-multi-carousel"
import ClipLoader from "react-spinners/ClipLoader"
import CustomModal from "../../../layout/modal/modal"
import { OVERRIDE } from "../../../utils/constants"

const Gallery = ({ color, bg, language }) => {
  const { galleries: gallery } = useSelector((state) => state.card)
  const [imageActive, setImageActive] = useState(0)
  const [viewPicture, setViewPicture] = useState(false)
  const responsive = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 4,
      slidesToSlide: 4,
    },
  }

  return (
    <div className="card_gallery">
      <div className="card_title">{language.gallery}</div>
      <Carousel
        responsive={responsive}
        arrows={false}
        showDots={true}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {gallery.length > 0 &&
          gallery.map((item, index) => (
            <img
              key={index}
              src={item.image}
              onClick={() => [setViewPicture(true), setImageActive(index)]}
              alt="gallery"
            />
          ))}
      </Carousel>
      {viewPicture && (
        <ViewPicture
          language={language}
          bg={bg}
          color={color}
          gallery={gallery}
          imageActive={imageActive}
          onHide={() => setViewPicture(false)}
        />
      )}
    </div>
  )
}

const ViewPicture = ({ bg, onHide, imageActive, gallery, color, language }) => {
  const responsive = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  }

  const carouselRef = useRef(null)
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.goToSlide(imageActive)
      setTimeout(() => {
        setShow(true)
      }, [800])
    }
  }, [])
  return (
    <CustomModal
      rtl={language.rtl}
      title={language.gallery}
      bg={bg}
      color={color}
      isBgShow={true}
      onHide={onHide}
    >
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        arrows={true}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {gallery.length > 0 &&
          gallery.map((item, index) =>
            show ? (
              <img
                key={index}
                src={item.image}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  aspectRatio: 4 / 3,
                }}
                alt="gallery"
              />
            ) : (
              <ClipLoader
                color={color}
                loading={true}
                cssOverride={OVERRIDE}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ),
          )}
      </Carousel>
    </CustomModal>
  )
}

export default memo(Gallery)
