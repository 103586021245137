import { configureStore } from "@reduxjs/toolkit"
import sidebarSlice from "./slices/sidebarSlice"
import userSlice from "./slices/userSlice"
import cardSlice from "./slices/cardSlice"
import langSlice from "./slices/langSlice"

export const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    card: cardSlice,
    user: userSlice,
    language: langSlice,
  },
})
