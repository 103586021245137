import "react-phone-number-input/style.css"
import { useState, memo, useEffect, useLayoutEffect, useCallback } from "react"
import { useHistory, Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { parsePhoneNumber } from "libphonenumber-js"
import { Helmet } from "react-helmet-async"
import PhoneInput from "react-phone-number-input"
import ClipLoader from "react-spinners/ClipLoader"
import Panel from "../layout/Panel/Panel"
import image from "../assets/business.png"
import Button from "../common/Button"
import CustomModal from "../layout/modal/modal"
import Api from "../api/api"
import Loader from "../components/loader/loader"
import { OVERRIDE } from "../utils/constants"
import { lookupCountryName } from "../utils/countryNames"

const Dashboard = () => {
  const language = useSelector((state) => state.language)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [cards, setCards] = useState([])
  const [languagesData, setLanguagesData] = useState([])
  const [showLangModal, setShowLangModal] = useState(false)
  const [editLang, setEditLang] = useState({ id: 0, cid: 0 })

  const getCards = useCallback(async () => {
    const res = await Api.getAllMyCards()
    if (res.status === 200) {
      setCards(res.data.data)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [setCards])

  const getAllLanguages = useCallback(async () => {
    const res = await Api.getAllLanguages()
    if (res.status === 200) {
      setLanguagesData(res.data.data)
    }
  }, [setLanguagesData])

  const deleteCard = useCallback(
    async (id) => {
      setIsLoading(true)
      const res = await Api.deleteCard(id)
      if (res.status === 200) {
        getCards()
        setIsLoading(false)
      }
      setIsLoading(false)
    },
    [setCards],
  )

  useLayoutEffect(() => {
    getCards()
    getAllLanguages()
  }, [])
  return (
    <>
      <Helmet>
        <title>{`Wazcard`}</title>
        <meta
          name="description"
          content={`Wazcard, the digital business cards`}
        />
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Panel>
            {cards && cards.length > 0 ? (
              <div
                className="c_card"
                style={{ height: "100%", overflowY: "scroll" }}
              >
                <Button
                  onClick={() => setShowModal(true)}
                  style={{
                    marginLeft: !language.rtl && "auto",
                    marginBottom: 14,
                  }}
                >
                  {language.createCard}
                </Button>
                <div style={{ overflowX: "scroll" }}>
                  <table className="cards_table">
                    <thead>
                      <tr>
                        <th>{language.sr}</th>
                        <th style={{ width: "30%" }}>{language.title}</th>
                        <th>{language.url}</th>
                        <th>{language.language}</th>
                        <th>{language.status}</th>
                        <th>{language.action}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cards.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item.title}</td>
                          <td>
                            <Link to={`/preview/${item.URL_title}`}>
                              {`${window.location.host}/preview/${item.URL_title}`}
                            </Link>
                          </td>
                          <td>
                            <span
                              onClick={() => {
                                setEditLang({
                                  id: item.language.id,
                                  cid: item.id,
                                })
                                setShowLangModal(true)
                              }}
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </span>
                            &nbsp;&nbsp;
                            {item.language.lang_name}
                          </td>
                          <td>
                            <span
                              style={{
                                display: "block",
                                padding: "2px 6px",
                                width: "fit-content",
                                textTransform: "capitalize",
                                fontWeight: 600,
                                borderRadius: 6,
                                fontSize: 13,
                                background:
                                  item.status === "active"
                                    ? "#2563eb33"
                                    : "#ff537433",
                                color:
                                  item.status === "active"
                                    ? "#2563EB"
                                    : "#ff5374",
                              }}
                            >
                              {`${item.status}`}
                            </span>
                          </td>
                          <td>
                            <Link to={`/dashboard/card/${item.URL_title}`}>
                              <i className="bi bi-pencil-fill"></i>
                            </Link>
                            &nbsp;&nbsp;
                            <span onClick={() => deleteCard(item.id)}>
                              <i className="bi bi-trash-fill"></i>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <img
                  src={image}
                  alt=""
                />
                <p>{language.pleaseCreateNewBusinessCard}</p>
                <Button onClick={() => setShowModal(true)}>
                  {language.createCard}
                </Button>
              </div>
            )}
            {showModal && (
              <CustomModal
                title="Create New Card"
                onHide={() => setShowModal(false)}
              >
                <AddForm languagesData={languagesData} />
              </CustomModal>
            )}

            {showLangModal && (
              <CustomModal
                title="Edit Card Language"
                onHide={() => setShowLangModal(false)}
              >
                <EditLanguage
                  languages={languagesData}
                  id={editLang.id}
                  cid={editLang.cid}
                />
              </CustomModal>
            )}
          </Panel>
        </div>
      )}
    </>
  )
}

const AddForm = memo(({ languagesData }) => {
  const history = useHistory()
  const [isDisabled, setIsDisabled] = useState(false)
  const [data, setData] = useState({
    title: "",
    lang_id: "",
    number: "",
  })

  const submitHandle = async (e) => {
    e.preventDefault()
    const parsedPhoneNumber = parsePhoneNumber(data.number)
    if (!parsedPhoneNumber.isValid()) return alert("Invalid number")
    const countryCode = parsedPhoneNumber.country.toLowerCase()

    let title = data.title.trim().replaceAll(" ", "-")
    title = `${title}.${countryCode}`.toLowerCase()

    setIsDisabled(true)
    const res = await Api.createCard({
      title: data.title.trim(),
      URL_title: `${title}`,
      lang_id: data.lang_id,
      number: data.number,
      deviceName: getDeviceName(),
      country: lookupCountryName(countryCode),
    })
    if (res.status === 201) {
      history.push(`/dashboard/card/${title}`)
      setIsDisabled(false)
    } else if (res.status === 409) {
      alert("Use unique title")
      setIsDisabled(false)
    } else {
      setIsDisabled(false)
    }
  }

  const getDeviceName = () => {
    const platform = navigator.platform?.toLowerCase()
    let deviceName = ""

    if (/win/.test(platform)) {
      deviceName = "Windows"
    } else if (/mac/.test(platform)) {
      deviceName = "Mac"
    } else if (/linux/.test(platform)) {
      deviceName = "Linux"
    } else if (/iphone|ipad|ipod/.test(platform)) {
      deviceName = "iOS"
    } else if (/arm|aarch/.test(platform)) {
      deviceName = "ARM-based"
    } else if (/android/.test(platform)) {
      deviceName = "Android"
    } else {
      deviceName = ""
    }

    return deviceName
  }

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <input
          type="text"
          value={data.title}
          onChange={(e) =>
            setData((prev) => ({ ...prev, title: e.target.value }))
          }
          placeholder="Business Title"
          required
        />
      </div>
      <div className="c_input_wrapper">
        <select
          required
          name="language"
          value={data.lang_id}
          onChange={(e) =>
            setData((prev) => ({ ...prev, lang_id: e.target.value }))
          }
        >
          <option value="">-- Select Language --</option>
          {languagesData.map((item) => (
            <option value={item.id}>{item.lang_name}</option>
          ))}
        </select>
      </div>
      <div className="c_input_wrapper">
        <PhoneInput
          required
          value={data.number}
          onChange={(value) => setData((prev) => ({ ...prev, number: value }))}
          international
          placeholder="Number"
        />
      </div>
      <Button
        disabled={isDisabled}
        width="100%"
      >
        {!isDisabled ? (
          "Create"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  )
})

const EditLanguage = memo(({ languages, id, cid }) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [data, setData] = useState({
    id,
    cid,
  })

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisabled(true)
    const res = await Api.updateCardLanguage(data)
    if (res.status === 200) {
      window.location.reload()
    }
  }

  useEffect(() => {
    setData({ id, cid })
  }, [])

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <select
          required
          name="language"
          value={data?.id}
          onChange={(e) => setData((prev) => ({ ...prev, id: e.target.value }))}
        >
          <option value="">-- Select Language --</option>
          {languages.map((item) => (
            <option value={item.id}>{item.lang_name}</option>
          ))}
        </select>
      </div>
      <Button
        disabled={isDisabled}
        width="100%"
      >
        {!isDisabled ? (
          "Edit"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  )
})
export default Dashboard
