import "./auth.css"
import { Helmet } from "react-helmet-async"

const Auth = ({ title, children }) => {
  return (
    <div className="auth_page">
      <Helmet>
        <title>{`Wazcard`}</title>
        <meta
          name="description"
          content={`Wazcard, the digital business cards`}
        />
      </Helmet>
      <div className="auth_cont">
        <div className="auth_child2">
          <div className="title_1">{title}</div>
          <br />
          {children}
        </div>
      </div>
    </div>
  )
}

export default Auth
