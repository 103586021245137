import DesignCard from "../../../layout/designCard/designCard"
import { useDispatch, useSelector } from "react-redux"
import { designCard } from "../../../store/slices/cardSlice"
import Api from "../../../api/api"

const CardDesign = ({ toggle, setToggle }) => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.card)
  const language = useSelector((state) => state.language)

  const inputHandle = (e) => {
    const { name, value } = e.target
    dispatch(designCard({ ...state, [name]: value }))
  }

  const inputFile = (e) => {
    const { name, files } = e.target
    if (files[0]) {
      const reader = new FileReader()
      reader.onload = async (event) => {
        // const file = base64StringToFile(event.target.result)
        dispatch(designCard({ ...state, [name]: event.target.result }))

        const formData = {
          id: state.id,
          image: event.target.result,
        }

        if (name === "logo") {
          await Api.updateLogo(formData)
        } else if (name === "cover_photo") {
          await Api.updateCoverPhoto(formData)
        } else if (name === "bg_image") {
          await Api.updateBgPhoto(formData)
        }
      }
      reader.readAsDataURL(files[0])
    }
  }

  const submitData = async (e) => {
    const { name, value } = e.target
    if (name === "title") {
      await Api.updateTitle({ id: state.id, title: value.trim() })
    } else if (name === "slogan") {
      await Api.updateSlogan({ id: state.id, slogan: value.trim() })
    } else if (name === "text_color") {
      await Api.updateTextColor({ id: state.id, text_color: value })
    } else if (name === "bg_color") {
      await Api.updateBgColor({ id: state.id, bg_color: value })
    } else if (name === "cover_color") {
      await Api.updateCoverColor({ id: state.id, cover_color: value })
    }
  }

  return (
    <DesignCard
      title={language.cardDesign}
      toggle={toggle}
      onShow={() => setToggle(1, !toggle)}
    >
      <Image
        label={language.businessLogo}
        name="logo"
        inputHandle={inputFile}
        accept={"image/png"}
      />
      <Image
        label={language.coverPhoto}
        name="cover_photo"
        inputHandle={inputFile}
        accept={"image/png, image/jpeg, image/jpg"}
      />
      <Image
        label={language.backgroundImage}
        name="bg_image"
        inputHandle={inputFile}
        accept={"image/png, image/jpeg, image/jpg"}
      />
      <Input
        type="text"
        label={language.businessName}
        name="title"
        value={state.title}
        inputHandle={inputHandle}
        submitData={submitData}
      />
      <Input
        type="text"
        label={language.slogan}
        name="slogan"
        value={state.slogan}
        inputHandle={inputHandle}
        submitData={submitData}
      />
      <Input
        type="color"
        label={language.color}
        name="text_color"
        value={state.text_color}
        inputHandle={inputHandle}
        submitData={submitData}
      />
      {/* <Input
        type="color"
        label="Cover Color"
        name="cover_color"
        value={state.cover_color}
        inputHandle={inputHandle}
        submitData={submitData}
      /> */}
      <Input
        type="color"
        label={language.backgroundColor}
        name="bg_color"
        value={state.bg_color}
        inputHandle={inputHandle}
        submitData={submitData}
      />
    </DesignCard>
  )
}

const Image = ({ label, name, inputHandle, accept }) => {
  return (
    <div className="design_input_wrapper">
      <label>{label}</label>
      <input
        type="file"
        accept={accept}
        name={name}
        onChange={inputHandle}
      />
    </div>
  )
}

const Input = ({ type, label, name, value, inputHandle, submitData }) => {
  return (
    <div className="design_input_wrapper">
      <label>{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={inputHandle}
        onBlur={submitData}
      />
    </div>
  )
}

export default CardDesign
