import "./review.css"
import { memo, useState } from "react"
import Rating from "../rating/rating"
import EditReview from "./edit"
import { useDispatch } from "react-redux"
import { deleteReview } from "../../store/slices/cardSlice"
import Api from "../../api/api"

const ReviewCard = ({
  id,
  title,
  logo,
  name,
  description,
  createdAt,
  rating,
  showAction,
  bg,
  color,
  mr,
}) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const deleteReviewHandle = async (id) => {
    await Api.deleteReview(id)
  }
  return (
    <div
      key={id}
      className="review_wrapper"
      style={{ background: bg, color: color, marginRight: mr }}
    >
      <div className="review_head">
        <p>{name}</p>
        <span>{createdAt}</span>
      </div>

      <div className="review_actions">
        {name && <Rating rating={rating} />}
        {showAction && (
          <div>
            <span onClick={() => setShowModal(true)}>
              <i className="bi bi-pencil-square"></i>
            </span>
            &nbsp; &nbsp;
            <span
              onClick={() => [dispatch(deleteReview(id)), deleteReviewHandle(id)]}
            >
              <i className="bi bi-trash3"></i>
            </span>
          </div>
        )}
      </div>

      <p style={{ fontSize: 14 }}>{description}</p>
      {showModal && (
        <EditReview
          id={id}
          name={name}
          rating={rating}
          description={description}
          logo={logo}
          title={title}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  )
}

export default memo(ReviewCard)
