import "./builder.css"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import CardDesign from "./options/design"
import Gallery from "./options/gallery"
import MainIcons from "./options/icons"
import OperatingHours from "./options/hours"
import Reviews from "./options/reviews"
import General from "./options/general"
import CardPreview from "../preview/preview"
import Floating from "./options/floating"
import Button from "../../common/Button"

const CardBuilder = () => {
  const history = useHistory()
  const { URL_title } = useSelector((state) => state.card)
  const [showOptions, setShowOptions] = useState({
    design: false,
    icons: false,
    hours: false,
    gallery: false,
    reviews: false,
    general: false,
    floating: false,
  })

  const handleToggle = (id, bool) => {
    if (id === 1) {
      setShowOptions({
        design: bool,
        icons: false,
        hours: false,
        gallery: false,
        reviews: false,
        general: false,
        floating: false,
      })
    } else if (id === 2) {
      setShowOptions({
        design: false,
        icons: bool,
        gallery: false,
        reviews: false,
        general: false,
        floating: false,
      })
    } else if (id === 3) {
      setShowOptions({
        design: false,
        icons: false,
        hours: bool,
        gallery: false,
        reviews: false,
        general: false,
        floating: false,
      })
    } else if (id === 4) {
      setShowOptions({
        design: false,
        icons: false,
        hours: false,
        gallery: bool,
        reviews: false,
        general: false,
        floating: false,
      })
    } else if (id === 5) {
      setShowOptions({
        design: false,
        icons: false,
        hours: false,
        gallery: false,
        reviews: bool,
        general: false,
        floating: false,
      })
    } else if (id === 6) {
      setShowOptions({
        design: false,
        icons: false,
        hours: false,
        gallery: false,
        reviews: false,
        general: bool,
        floating: false,
      })
    } else if (id === 7) {
      setShowOptions({
        design: false,
        icons: false,
        hours: false,
        gallery: false,
        reviews: false,
        general: false,
        floating: bool,
      })
    }
  }
  return (
    <>
      <div className="builder_cont">
        <div className="builder_options">
          <CardDesign
            toggle={showOptions.design}
            setToggle={handleToggle}
          />
          <MainIcons
            toggle={showOptions.icons}
            setToggle={handleToggle}
          />
          <OperatingHours
            toggle={showOptions.hours}
            setToggle={handleToggle}
          />
          <Gallery
            toggle={showOptions.gallery}
            setToggle={handleToggle}
          />
          <Reviews
            toggle={showOptions.reviews}
            setToggle={handleToggle}
          />
          <Floating
            toggle={showOptions.floating}
            setToggle={handleToggle}
          />
          <General
            toggle={showOptions.general}
            setToggle={handleToggle}
          />
          <Button onClick={() => history.push(`/preview/${URL_title}`)}>
            Preview
          </Button>
        </div>
        <CardPreview />
      </div>
    </>
  )
}

export default CardBuilder
