import Switch from "react-switch"
import DesignCard from "../../../layout/designCard/designCard"
import { useDispatch, useSelector } from "react-redux"
import { editSettings } from "../../../store/slices/cardSlice"
import Api from "../../../api/api"

const General = ({ toggle, setToggle }) => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.card)
  const language = useSelector((state) => state.language)

  const settingsHandle = async (key) => {
    dispatch(
      editSettings({
        ...state,
        settings: { ...state.settings, [key]: !state.settings[key] },
      }),
    )

    await Api.updateSettings({ ...state.settings, [key]: !state.settings[key] })
  }

  return (
    <DesignCard
      title={language.generalSettings}
      toggle={toggle}
      onShow={() => setToggle(6, !toggle)}
    >
      <Option
        label={language.floatingButton}
        value={state.settings.floating}
        onChange={() => settingsHandle("floating")}
      />
      <Option
        label={language.logo}
        value={state.settings.logo}
        onChange={() => settingsHandle("logo")}
      />
      <Option
        label={language.coverPhoto}
        value={state.settings.cover_photo}
        onChange={() => settingsHandle("cover_photo")}
      />
      <Option
        label={language.backgroundImage}
        value={state.settings.bg_image}
        onChange={() => settingsHandle("bg_image")}
      />
      <Option
        label={language.likeButton}
        value={state.settings.like_button}
        onChange={() => settingsHandle("like_button")}
      />
      <Option
        label={language.title}
        value={state.settings.title}
        onChange={() => settingsHandle("title")}
      />
      <Option
        label={language.slogan}
        value={state.settings.slogan}
        onChange={() => settingsHandle("slogan")}
      />
      <Option
        label={language.mainIcons}
        value={state.settings.icons}
        onChange={() => settingsHandle("icons")}
      />
      <Option
        label={language.openingHours}
        value={state.settings.hours}
        onChange={() => settingsHandle("hours")}
      />
      <Option
        label={language.gallery}
        value={state.settings.gallery}
        onChange={() => settingsHandle("gallery")}
      />
      <Option
        label={language.reviews}
        value={state.settings.reviews}
        onChange={() => settingsHandle("reviews")}
      />
    </DesignCard>
  )
}

const Option = ({ label, value, onChange }) => {
  return (
    <div
      className="gen_setting"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <p>{label}</p>
      <Switch
        checked={value}
        onChange={onChange}
        width={42}
        height={20}
        onColor="#2563EB"
        handleDiameter={16}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      />
    </div>
  )
}

export default General
