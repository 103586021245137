import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Auth from "../layout/auth/auth"
import Button from "../common/Button"
import Api from "../api/api"
import ClipLoader from "react-spinners/ClipLoader"
import { OVERRIDE } from "../utils/constants"
import { addUser } from "../store/slices/userSlice"
import { Notifications } from "../helper/notifications"

const Login = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const language = useSelector((state) => state.language)
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({ email: "", password: "" })

  const inputHandle = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)
    const res = await Api.userLogin(data)
    if (res.status === 200) {
      dispatch(addUser(res.data.user))
      history.push("/dashboard")
      Notifications("success", res.data.message)
    }
    setIsDisable(false)
  }

  return (
    <Auth title={language.login}>
      <form
        onSubmit={submitHandle}
        className="auth_form"
      >
        <div className="c_input_wrapper">
          <input
            type="email"
            name="email"
            value={data.email}
            onChange={inputHandle}
            placeholder={language.emailAddress}
            required
          />
        </div>
        <div className="c_input_wrapper">
          <input
            type="password"
            name="password"
            value={data.password}
            onChange={inputHandle}
            placeholder={language.password}
            required
          />
        </div>
        <Link
          to="/forget"
          className="form_link"
        >
          <span>{language.forgetPassword}</span>
        </Link>
        <Button
          disabled={isDisable}
          className="btn_primary"
        >
          {!isDisable ? (
            language.login
          ) : (
            <ClipLoader
              color="#FFFFFF"
              loading={true}
              cssOverride={OVERRIDE}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
        </Button>
        <Link
          to="/signup"
          className="form_link"
          style={{ textAlign: "center" }}
        >
          {language.dontHaveAccount} <span>{language.signup}</span>
        </Link>
      </form>
    </Auth>
  )
}

export default Login
