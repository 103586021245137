import ReactStars from "react-rating-stars-component"
import styled from "styled-components"
import Button from "../../common/Button"
import CustomModal from "../../layout/modal/modal"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { editReview } from "../../store/slices/cardSlice"
import Api from "../../api/api"

const EditReview = ({ id, title, logo, name, description, rating, onHide }) => {
  const dispatch = useDispatch()
  const language = useSelector((state) => state.language)
  const [data, setData] = useState({
    id: id,
    name: "",
    rating: 0,
    description: "",
  })

  const dataEditHandle = async () => {
    dispatch(editReview(data))
    onHide()
    await Api.editReview(data)
  }

  useEffect(() => {
    setData((prev) => ({ id, name, rating, description }))
  }, [rating])

  return (
    <CustomModal
      title={language.editReviews}
      isBgShow={false}
      onHide={onHide}
    >
      <LogoWrapper>
        <img
          src={logo}
          alt={title}
        />
      </LogoWrapper>
      <P>
        {language.rateYourExperienceWith} <strong>{title}</strong>
      </P>
      {data.rating && (
        <ReactStars
          count={5}
          size={28}
          activeColor="#F3B455"
          value={data.rating}
          onChange={(value) => setData((prev) => ({ ...prev, rating: value }))}
        />
      )}
      <div style={{ marginBottom: 8 }}>
        <Input
          type="text"
          value={data.name}
          onChange={(e) =>
            setData((prev) => ({ ...prev, name: e.target.value }))
          }
          placeholder={language.name}
        />
      </div>
      <div style={{ marginBottom: 8 }}>
        <TextArea
          type="text"
          value={data.description}
          onChange={(e) =>
            setData((prev) => ({ ...prev, description: e.target.value }))
          }
          placeholder={language.yourExperience}
        />
      </div>
      <Button
        width="100%"
        onClick={dataEditHandle}
      >
        {language.editReviews}
      </Button>
    </CustomModal>
  )
}

const Input = styled.input`
  background-color: transparent;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 8px;
  font-size: 15px;
  padding: 10px;
  width: 100%;

  &::placeholder {
    color: #c0c0c0;
  }
`

const TextArea = styled.textarea`
  background-color: transparent;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 8px;
  font-size: 15px;
  padding: 10px;
  width: 100%;

  &::placeholder {
    color: #c0c0c0;
  }
`
const P = styled.p`
  text-align: center;
  margin-top: 8px;
`
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
  }
`

export default EditReview
