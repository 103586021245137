import { useCallback, useEffect, useLayoutEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Helmet } from "react-helmet-async"
import { designCard } from "../store/slices/cardSlice"
import Api from "../api/api"
import CardPreview from "../components/preview/preview"
import Loader from "../components/loader/loader"
import { langLoader } from "../store/slices/langSlice"

const PreviewCard = () => {
  const { title } = useParams()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [isCard, setIsCard] = useState(false)
  const [og, setOG] = useState({ title: "", logo: "", color: "", bg: "" })

  const fetchCardData = useCallback(async () => {
    const res = await Api.getCardPublic(title)
    if (res.status === 200) {
      if (res.data.data.language.rtl) {
        document.querySelector("body").classList.add("preview_rtl")
      }
      setOG({
        title: res.data.data.title,
        logo: res.data.data.logo,
        color: res.data.data.text_color,
        bg: res.data.data.bg_color,
      })
      dispatch(designCard(res.data.data))
      setIsCard(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (res.status === 404) {
      setIsCard(false)
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }, [])

  const addLinkRel = async (name, content) => {
    const isExisting = document.querySelector(`link[rel="${name}"]`)
    if (isExisting) {
      isExisting.href = content
    } else {
      const link = document.createElement("link")
      link.rel = name
      link.href = content
      document.head.appendChild(link)
    }
  }

  const addIOSMeta = async (name, content) => {
    const isExisting = document.querySelector(`meta[name="${name}"]`)
    if (isExisting) {
      isExisting.content = content
    } else {
      const meta = document.createElement("meta")
      meta.name = name
      meta.content = content
      document.head.appendChild(meta)
    }
  }

  const iosSupportTags = async () => {
    addLinkRel("apple-touch-icon", og.logo)
    addIOSMeta("apple-mobile-web-app-capable", "yes")
    addIOSMeta("apple-mobile-web-app-status-bar-style", "default")
    addIOSMeta("apple-mobile-web-app-title", og.title)
  }

  const createManifest = async () => {
    const manifestData = {
      name: og.title,
      short_name: og.title,
      start_url: window.location.href,
      theme_color: og.color,
      background_color: og.bg,
      display: "standalone",
      prefer_related_applications: true,
      icons: [
        {
          src: og.logo,
          sizes: "180x180 120x120 64x64 32x32 24x24 16x16",
          type: "image/png",
          purpose: "any maskable",
        },
      ],
    }

    const manifestBlob = new Blob([JSON.stringify(manifestData)], {
      type: "application/json",
    })
    const manifestURL = URL.createObjectURL(manifestBlob)
    addLinkRel("manifest", manifestURL)
  }

  useEffect(() => {
    createManifest()
    iosSupportTags()
  }, [og])

  useLayoutEffect(() => {
    dispatch(langLoader(false))
    fetchCardData()

    return () => {
      document.querySelector("body").classList.remove("preview_rtl")
      document.querySelector("body").classList.remove("rtl")
    }
  }, [title])

  return (
    <>
      {isLoading && <Loader />}
      {!isCard ? (
        "No Card Exists"
      ) : (
        <>
          <Helmet>
            <title>{og.title}</title>
            <meta
              name="description"
              content={`${og.title}`}
            />
            <link
              rel="icon"
              type="image/png"
              href={`${og.logo}`}
            />
            <meta
              name="theme-color"
              content={og.color}
            />
          </Helmet>
          <div className="preview_page">
            <CardPreview />
          </div>
        </>
      )}
    </>
  )
}

export default PreviewCard
