import "./designCard.css"

const DesignCard = ({ children, title, toggle, onShow }) => {
  return (
    <div className="c_card card_design">
      <div
        className="c_head"
        onClick={onShow}
      >
        {title}
        <span style={{ transform: `rotate(${toggle ? 180 : 0}deg)` }}>
          <i className="bi bi-caret-down-fill"></i>
        </span>
      </div>
      {toggle && <div className="c_body">{children}</div>}
    </div>
  )
}

export default DesignCard
