import React from "react";
import HomeComponent from "../components/home/home";
import axios from "axios";

const Home = () => {
  const [testimonials, setTestimonials] = React.useState([]);
  const [hero, setHero] = React.useState({});
  const [working, setWorking] = React.useState({});
  const [business, setBusiness] = React.useState({});

  const getTestimonials = async () => {
    await axios
      .get("api/v1/testimonial")
      .then((res) => {
        setTestimonials(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = async () => {
    await axios
      .get("api/v1/content")
      .then((res) => {
        const data = res.data.message;
        setHero(data.hero);
        setWorking(data.working);
        setBusiness(data.business);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getTestimonials();
    getData();
  }, []);

  return (
    <>
      <HomeComponent
        testimonials={testimonials}
        hero={hero}
        working={working}
        business={business}
      />
    </>
  );
};

export default Home;
