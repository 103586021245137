import React, { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { designCard } from "../../../store/slices/cardSlice"
import Api from "../../../api/api"

const CardCover = ({
  showCover,
  showLikeBtn,
  coverPhoto,
  coverColor,
  likes,
}) => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.card)
  const [isLiked, setIsLiked] = useState(false)

  const likeHandle = async () => {
    let prevData = JSON.parse(localStorage.getItem("cardLikes")) || []
    const titleExists = prevData.some((item) => item.title === state.URL_title)
    let data = []

    if (!window.location.pathname.includes("/card/")) {
      setIsLiked((prev) => !prev)

      if (!isLiked) {
        if (!titleExists) {
          dispatch(designCard({ ...state, likes: state.likes + 1 }))
          data = [...prevData, { title: state.URL_title }]
          localStorage.setItem("cardLikes", JSON.stringify(data))
          await Api.likeUnlikeCard({ URL_title: state.URL_title, status: true })
        }
      } else {
        if (titleExists) {
          dispatch(
            designCard({
              ...state,
              likes: state.likes <= 0 ? 0 : state.likes - 1,
            }),
          )
          data = prevData.filter((item) => item.title !== state.URL_title)
          localStorage.setItem("cardLikes", JSON.stringify(data))
          await Api.likeUnlikeCard({
            URL_title: state.URL_title,
            status: false,
          })
        }
      }
    }
  }

  const updateViewCount = async () => {
    if (!window.location.pathname.includes("/preview/")) return
    let prevData = JSON.parse(localStorage.getItem("cardViews")) || []
    const titleExists = prevData.some((item) => item.title === state.URL_title)
    let data = []

    if (!titleExists) {
      const res = await Api.updateViewCount(state.URL_title)
      if (res.status === 200) {
        data = [...prevData, { title: state.URL_title }]
        localStorage.setItem("cardViews", JSON.stringify(data))
      }
    }
  }

  useEffect(() => {
    let prevData = JSON.parse(localStorage.getItem("cardLikes")) || []
    const titleExists = prevData.some((item) => item.title === state.URL_title)
    if (titleExists) setIsLiked(true)
    updateViewCount()
  }, [])

  return (
    <div
      className="cover_image_wrapper"
      // style={{ background: coverColor }}
      style={{ background: "transparent" }}
    >
      {showCover && coverPhoto && (
        <img
          src={coverPhoto}
          alt=""
        />
      )}
      {showLikeBtn && (
        <div
          className="like_wrapper"
          onClick={likeHandle}
        >
          <span>
            {!isLiked ? (
              <i className="bi bi-hand-thumbs-up"></i>
            ) : (
              <i className="bi bi-hand-thumbs-up-fill"></i>
            )}
          </span>
          <span>{likes}</span>
        </div>
      )}
    </div>
  )
}

export default memo(CardCover)
