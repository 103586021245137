import "./Header.css"
import { useState, useRef, useLayoutEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toggleSidebar } from "../../store/slices/sidebarSlice"
import Usermenu from "../usermenu/Usermenu"
import Api from "../../api/api"
import { LANG_NAME } from "../../utils/constants"
import { addLang, langLoader } from "../../store/slices/langSlice"

const Header = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const showUserMenuRef = useRef()
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [languagesData, setLanguagesData] = useState([])
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem(LANG_NAME) || "English",
  )

  const langHandle = async (e) => {
    const lang_code = e.target.value
    setSelectedLang(lang_code)
    localStorage.setItem(LANG_NAME, lang_code)
    dispatch(langLoader(true))
    window.location.reload()
  }

  const getAllLanguages = useCallback(async () => {
    const res = await Api.getAllLanguages()
    if (res.status === 200) {
      setLanguagesData(res.data.data)
    }
  }, [setLanguagesData])

  useLayoutEffect(() => {
    getAllLanguages()
  }, [])

  return (
    <header className="panel_header c_card">
      <div className="header_cont">
        <div className="header_left">
          <div className="hamburger">
            <span onClick={() => dispatch(toggleSidebar())}>
              <i className="bi bi-list"></i>
            </span>
          </div>
        </div>
        <div className="header_center"></div>
        <div className="header_right">
          <div className="lang_select_wrapper">
            <i className="bi bi-globe2"></i>
            <select
              value={selectedLang}
              onChange={langHandle}
            >
              {languagesData.map((item) => (
                <option
                  key={item.lang_code}
                  value={item.lang_code}
                >
                  {item.lang_name}
                </option>
              ))}
            </select>
          </div>
          <div
            className="avatar"
            onClick={() => setShowUserMenu(true)}
          >
            <img
              src={user?.avatar}
              alt=""
            />
            {showUserMenu && (
              <Usermenu
                avatar={user.avatar}
                name={user.name}
                email={user.email}
                showUserMenuRef={showUserMenuRef}
                setShowUserMenu={setShowUserMenu}
              />
            )}
          </div>
          <span onClick={() => setShowUserMenu(true)}>{user?.name}</span>
        </div>
      </div>
    </header>
  )
}

export default Header
