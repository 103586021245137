import { memo } from "react"
import { saveAs } from "file-saver"

const Floating = ({ isShow, color, fbg, title, icon, number, language }) => {
  const handleVCFDownload = () => {
    const vCardContent =
      "BEGIN:VCARD\n" +
      "VERSION:3.0\n" +
      "FN:" +
      title +
      "\n" +
      "TYPE:Work\n" +
      "TEL:" +
      number +
      "\n" +
      "END:VCARD\n"

    // Create a Blob with the vCard content
    const blob = new Blob([vCardContent], { type: "text/vcard" })

    // Save the file using FileSaver.js
    saveAs(blob, "contact.vcf")
  }

  const handleShare = async () => {
    if (window.location.pathname.includes("/card/")) return
    try {
      await navigator.share({
        title,
        text: title,
        url: `${window.location.href}`,
      })
    } catch (error) {
      console.error("Error sharing:", error)
    }
  }

  return (
    <div
      className="card_floating"
      style={{ background: fbg }}
    >
      <a
        onClick={handleVCFDownload}
        className="card_button"
      >
        + {language.addContact}
      </a>
      {isShow && (
        <div
          className="floating_btn"
          style={{ background: fbg }}
        >
          <a
            href={`tel:${number}`}
            className="card_button"
          >
            <img
              src={icon}
              alt="floating icon"
            />
          </a>
        </div>
      )}
      <button
        className="card_button"
        onClick={handleShare}
      >
        <svg
          width="16"
          height="13"
          viewBox="0 0 16 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.165 8.66667C6.23171 8.66667 2.24838 10.3333 1.83171 12.4917C1.81421 12.5873 1.76381 12.6738 1.68923 12.7362C1.61466 12.7986 1.52061 12.833 1.42338 12.8333H1.34004C1.24046 12.8332 1.14482 12.7944 1.07338 12.725C1.01683 12.645 0.990271 12.5476 0.998378 12.45C1.14063 10.1011 2.16337 7.89217 3.8625 6.2641C5.56163 4.63602 7.81219 3.70851 10.165 3.66667V1.51667C10.1642 1.46078 10.1749 1.40531 10.1964 1.3537C10.2179 1.3021 10.2498 1.25546 10.29 1.21667L10.4567 1.05001C10.4954 1.01095 10.5415 0.979956 10.5923 0.958802C10.6431 0.937649 10.6975 0.926758 10.7525 0.926758C10.8076 0.926758 10.862 0.937649 10.9128 0.958802C10.9636 0.979956 11.0096 1.01095 11.0484 1.05001L15.8734 5.87501C15.9132 5.91242 15.945 5.9576 15.9667 6.00776C15.9884 6.05793 15.9996 6.11201 15.9996 6.16667C15.9996 6.22134 15.9884 6.27542 15.9667 6.32559C15.945 6.37575 15.9132 6.42093 15.8734 6.45834L11.0484 11.2833C11.0096 11.3224 10.9636 11.3534 10.9128 11.3745C10.862 11.3957 10.8076 11.4066 10.7525 11.4066C10.6975 11.4066 10.6431 11.3957 10.5923 11.3745C10.5415 11.3534 10.4954 11.3224 10.4567 11.2833L10.29 11.1167C10.2498 11.0779 10.2179 11.0312 10.1964 10.9796C10.1749 10.928 10.1642 10.8726 10.165 10.8167V8.66667Z"
            fill={color}
          />
        </svg>{" "}
        {language.share}
      </button>
    </div>
  )
}

export default memo(Floating)
