import "react-toastify/dist/ReactToastify.css"
import "react-multi-carousel/lib/styles.css"
import "./App.css"
import "./rtl.css"
import { useCallback, useLayoutEffect, useState } from "react"
import { useLocation, Route } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer } from "react-toastify"
import decode from "jwt-decode"
import Cookies from "js-cookie"
import ProtectedRoute from "./protectedRoute"
import ProtectedAuth from "./protectedAuth"
import { PROTECTED_AUTH, PROTECTED_ROUTES, PUBLIC_ROUTES } from "./utils/routes"
import { hideSidebar } from "./store/slices/sidebarSlice"
import { getMe } from "./store/slices/userSlice"
import {
  JWT_STORAGE_NAME,
  LANG_NAME,
  LOCAL_STORAGE_NAME,
} from "./utils/constants"
import Loader from "./components/loader/loader"
import { addLang } from "./store/slices/langSlice"
import Api from "./api/api"
import i18n, { use } from "i18next"
import i18next from "i18next"
import { useTranslation, initReactI18next } from "react-i18next"

function App() {
  const { isLoading, rtl } = useSelector((state) => state.language)
  const dispatch = useDispatch()
  const location = useLocation()
  const [languages, setLanguages] = useState({})
  const [lang, setLang] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [selected, setSelected] = useState("en")

  const verifyToken = () => {
    const token = Cookies.get(JWT_STORAGE_NAME)
    if (token) {
      const decode_token = decode(token)
      if (
        decode_token.exp * 1000 < new Date().getTime() ||
        Cookies.get(JWT_STORAGE_NAME) === undefined ||
        localStorage.getItem(LOCAL_STORAGE_NAME) === "undefined"
      ) {
        Cookies.remove(JWT_STORAGE_NAME)
        localStorage.removeItem(JWT_STORAGE_NAME)
        localStorage.removeItem(LOCAL_STORAGE_NAME)
        window.location.reload()
      }
    }
  }

  const getLanguageByCode = useCallback(async () => {
    const res = await Api.getLanguageByCode(
      localStorage.getItem(LANG_NAME) || "en",
    )
    if (res.status === 200) {
      if (res.data.data?.rtl) {
        document.querySelector("body").classList.add("rtl")
      }
      dispatch(addLang(res.data.data))
    }
  }, [])

  const getAllLanguages = useCallback(async () => {
    const res = await Api.getAllLanguages()
    if (res.status === 200) {
      let resources = {}
      res.data.data.forEach((lang) => {
        resources[lang.lang_code] = {
          translation: lang,
        }
      })
      setLang(res.data.data)
      setLanguages(resources)
      setLoaded(true)
    }
  }, [])

  useLayoutEffect(() => {
    if (loaded) {
      const langSt = localStorage.getItem("wazcardLang")
      const chossen = lang.find((l) => l.lang_code === langSt)

      const lang_code = chossen?.lang_code || "en"
      const isRtl = chossen?.rtl || false

      if (isRtl) {
        document.querySelector("body").classList.add("rtl")
      }
      console.log(lang_code)
      setSelected(lang_code)
      i18next.changeLanguage(lang_code)
    }
  }, [loaded])

  useLayoutEffect(() => {
    verifyToken()
  }, [location.pathname])

  useLayoutEffect(() => {
    dispatch(hideSidebar())
  }, [location.pathname])

  useLayoutEffect(() => {
    if (
      Cookies.get(JWT_STORAGE_NAME) !== undefined &&
      !location.pathname.includes("/preview/")
    ) {
      dispatch(getMe())
    }

    if (!localStorage.getItem("wazcardLang")) {
      localStorage.setItem("wazcardLang", "en")
    }
  }, [])

  useLayoutEffect(() => {
    getLanguageByCode()
    getAllLanguages()
  }, [])

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: languages,
      lng: selected,
      fallbackLng: selected,

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    })

  return (
    <>
      {isLoading && <Loader />}
      {PUBLIC_ROUTES.map((route, index) => (
        <Route
          exact
          {...route}
          key={index}
        />
      ))}
      {PROTECTED_ROUTES.map((route, index) => (
        <ProtectedRoute
          exact
          {...route}
          key={index}
        />
      ))}
      {PROTECTED_AUTH.map((route, index) => (
        <ProtectedAuth
          exact
          {...route}
          key={index}
        />
      ))}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={rtl}
        draggable
        theme={"light"}
      />
    </>
  )
}

export default App
