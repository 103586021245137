import "./loader.css"
import ClipLoader from "react-spinners/ClipLoader"

function Loader() {
  const OVERRIDE = {
    display: "block",
    margin: "0 auto",
    border: "4px solid",
    borderColor: "#2563EB",
  }

  return (
    <div className="loader">
      <ClipLoader
        color="#2563EB"
        loading={true}
        cssOverride={OVERRIDE}
        size={80}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}

export default Loader
