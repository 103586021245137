import React, { memo, useState } from "react"

const OpeningHours = ({ bg, hours, language }) => {
  const today = new Date()
  const dayName = today.toLocaleString("en-US", { weekday: "long" })
  const [showAllDays, setShowAllDays] = useState(false)

  const isOpenHandle = (label, from, to, isOpen) => {
    const dayIndices = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    }

    const dayIndex = dayIndices[label]

    let currentTime = new Date()
    let currentDay = currentTime.getDay()
    let dayDiff = dayIndex - currentDay
    if (dayDiff < 0) {
      dayDiff += 7
    }

    let openingTime = new Date()
    openingTime.setHours(
      parseInt(from.split(":")[0]),
      parseInt(from.split(":")[1]),
      0,
      0,
    )
    openingTime.setDate(currentTime.getDate() + dayDiff)

    let closingTime = new Date()
    closingTime.setHours(
      parseInt(to.split(":")[0]),
      parseInt(to.split(":")[1]),
      0,
      0,
    )
    closingTime.setDate(currentTime.getDate() + dayDiff)

    if (
      currentTime.getTime() > openingTime.getTime() &&
      currentTime.getTime() < closingTime.getTime() &&
      isOpen
    ) {
      return true
    }

    return false
  }

  return (
    <div className="card_hours">
      <div className="card_title">{language?.openingHours}</div>
      {hours.length > 0 && (
        <div
          className="card_hours_wrapper c_card"
          style={{ background: bg, cursor: "pointer" }}
          onClick={() => setShowAllDays((prev) => !prev)}
        >
          {!showAllDays
            ? hours.map(
                ({ label, from, to, isOpen }) =>
                  label === dayName && (
                    <div className="card_hour">
                      <p style={{ fontWeight: 700 }}>
                        {language[label.toLowerCase()]}
                      </p>
                      <div>
                        <span
                          className={`${
                            isOpenHandle(label, from, to, isOpen) ? "on" : "off"
                          }`}
                        ></span>
                        <p>
                          {from}-{to}
                        </p>
                        <i className="bi bi-caret-down-fill"></i>
                      </div>
                    </div>
                  ),
              )
            : hours
                .map(({ label, from, to, isOpen }) => (
                  <div className="card_hour">
                    <p
                      style={{
                        fontWeight: label === dayName ? 700 : 500,
                      }}
                    >
                      {language[label.toLowerCase()]}
                    </p>
                    <div
                      style={{
                        fontWeight: label === dayName ? 700 : 500,
                      }}
                    >
                      <span
                        className={`${
                          isOpenHandle(label, from, to, isOpen) ? "on" : "off"
                        }`}
                      ></span>
                      <p>
                        {from}-{to}
                      </p>
                    </div>
                  </div>
                ))}
        </div>
      )}
    </div>
  )
}

export default memo(OpeningHours)
