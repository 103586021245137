import { memo } from "react"
import { saveAs } from "file-saver"
import Carousel from "react-multi-carousel"

const IconsSlider = ({ icons, title }) => {
  const responsive = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 5,
      slidesToSlide: 5,
    },
  }
  const handleVCFDownload = (type, number) => {
    if (type !== "phn") return
    const vCardContent =
      "BEGIN:VCARD\n" +
      "VERSION:3.0\n" +
      "FN:" +
      title +
      "\n" +
      "TYPE:Work\n" +
      "TEL:" +
      number +
      "\n" +
      "END:VCARD\n"

    // Create a Blob with the vCard content
    const blob = new Blob([vCardContent], { type: "text/vcard" })

    // Save the file using FileSaver.js
    saveAs(blob, "contact.vcf")
  }
  return (
    <div className="card_icons_slider">
      <Carousel
        responsive={responsive}
        arrows={false}
        showDots={true}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {icons.length > 0 &&
          icons.map(({ icon, type, value }, index) => (
            <a
              href={
                type === "sms" || type === "phn"
                  ? `tel:${value?.replaceAll(" ", "")}`
                  : type === "mail"
                  ? `mailto:${value?.replaceAll(" ", "")}`
                  : type === "wa"
                  ? `https://wa.me/${value?.replaceAll(" ", "")}`
                  : value?.replaceAll(" ", "")
              }
              target="_blank"
              // onClick={() =>
              //   handleVCFDownload(type, value?.replaceAll(" ", ""))
              // }
            >
              <img
                width={45}
                height={45}
                src={icon}
                key={index}
                alt="ICON"
              />
            </a>
          ))}
      </Carousel>
    </div>
  )
}

export default memo(IconsSlider)
