import { memo, useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import CustomModal from "../../../layout/modal/modal"
import { designCard } from "../../../store/slices/cardSlice"
import Api from "../../../api/api"

const AddIcons = ({ id, onHide, data, language }) => {
  const { title } = useParams()
  const dispatch = useDispatch()
  const [type, setType] = useState("")

  const addIconHandle = async (icon) => {
    if (!type) return alert("Select Type")

    try {
      const response = await fetch(icon)
      const blob = await response.blob()

      const reader = new FileReader()
      reader.onloadend = async () => {
        const base64String = reader.result
        const formData = {
          id: id,
          type: type,
          image: base64String,
        }
        // dispatch(addIcon({ type, icon:base64String, value: "" }))
        const res = await Api.addIcon(formData)
        if (res.status === 201) {
          fetchCardData()
        }
      }

      reader.readAsDataURL(blob)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchCardData = useCallback(async () => {
    const res = await Api.getCard(title)
    if (res.status === 200) {
      dispatch(designCard(res.data.data))
      onHide()
    }
  }, [])
  return (
    <CustomModal
      title={language.addIcons}
      onHide={onHide}
    >
      <div className="icons_cont">
        <div style={{ width: "100%" }}>
          <label>{language.iconUsedFor}</label>
          <div className="c_input_wrapper">
            <select onChange={(e) => setType(e.target.value)}>
              <option value="">--{language.select}--</option>
              <option value="wa">{language.whatsappNumber}</option>
              <option value="sms">{language.smsNumber}</option>
              <option value="phn">{language.phoneNumber}</option>
              <option value="mail">{language.mailAddress}</option>
              <option value="link">{language.linkAddress}</option>
            </select>
          </div>
        </div>
        {data &&
          data.map(({ imageUrl }, index) => (
            <img
              src={imageUrl}
              key={index}
              alt="Icons"
              onClick={() => addIconHandle(imageUrl)}
            />
          ))}
      </div>
    </CustomModal>
  )
}

export default memo(AddIcons)
