import { Helmet } from "react-helmet-async"
import ProfileComp from "../components/profile/profile"
import Panel from "../layout/Panel/Panel"

const Profile = () => {
  return (
    <div>
      <Helmet>
        <title>{`Account Setting | Wazcard`}</title>
        <meta
          name="description"
          content={`Wazcard, the digital business cards`}
        />
      </Helmet>
      <Panel>
        <ProfileComp />
      </Panel>
    </div>
  )
}

export default Profile
