import "./rating.css"

const Rating = ({ rating }) => {
  return (
    <div className="product_rating">
      {Array(rating)
        .fill()
        .map(() => (
          <i className="bi bi-star-fill star_on"></i>
        ))}
      {Array(5 - rating)
        .fill()
        .map(() => (
          <i className="bi bi-star-fill star_off"></i>
        ))}
    </div>
  )
}
export default Rating
