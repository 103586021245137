import { createSlice } from "@reduxjs/toolkit"
import { LANG_NAME } from "../../utils/constants"

const langSlice = createSlice({
  name: "langSlice",
  initialState: {
    lang_code: localStorage.getItem(LANG_NAME) || "en",
    rtl: false,
    isLoading: true,
  },
  reducers: {
    langLoader: (state, { payload }) => {
      return { ...state, isLoading: payload }
    },
    addLang: (state, { payload }) => {
      return { isLoading: false, ...payload }
    },
  },
})

export const { langLoader, addLang } = langSlice.actions
export default langSlice.reducer
