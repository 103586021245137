import "./modal.css"
import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { useSelector } from "react-redux"
import styled from "styled-components"

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 400px;
  height: auto;
  padding: 14px;
  border-radius: var(--border_radius);
  background: ${({ bg_image, isBgShow }) =>
    bg_image && isBgShow && `url(${bg_image})`};
  background-position: ${({ bg_image, isBgShow }) =>
    bg_image && isBgShow && "initial"};
  background-size: ${({ bg_image, isBgShow }) =>
    bg_image && isBgShow && "cover"};
  background-repeat: ${({ bg_image, isBgShow }) =>
    bg_image && isBgShow && "no-repeat"};
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};

  & .bi::before {
    color: ${({ color }) => color};
    font-weight: 600 !important;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    & {
      width: 330px;
    }
  }
`

const CustomModal = ({ children, rtl, isBgShow, title, bg, color, onHide }) => {
  const modalRef = useRef()
  const { bg_image, settings } = useSelector((state) => state.card)
  useEffect(() => {
    let handler = (e) => {
      if (!modalRef.current.contains(e.target)) {
        onHide()
      }
    }

    document.addEventListener("mousedown", handler)
    return () => document.removeEventListener("mousedown", handler)
  }, [onHide])
  return createPortal(
    <>
      <div className="custom_modal_overlay"></div>
      <Modal
        ref={modalRef}
        className="custom_modal_cont"
        bg={bg || "#FFFFFF"}
        bg_image={bg_image}
        isBgShow={bg_image && settings.bg_image && isBgShow}
        color={color || "#000"}
      >
        <div
          className="custom_modal_head"
          style={{ flexDirection: rtl && "row-reverse" }}
        >
          <p>{title}</p>
          <span onClick={onHide}>
            <i className="bi bi-x-lg"></i>
          </span>
        </div>
        <div className="custom_modal_body">{children}</div>
      </Modal>
    </>,
    document.getElementById("portal"),
  )
}

export default CustomModal
